<template>
  <div class="kecheng">
    <div class="dis_flex_between">
      <div class="btnBox">
        <div
          class="btnItem"
          :class="search.IsPurchase == 1 ? 'on' : ''"
          @click="btnClick(1)"
        >
          套餐赠送
        </div>

        <div
          class="btnItem"
          :class="search.IsPurchase == 0 ? 'on' : ''"
          @click="btnClick(0)"
        >
          单独购买
        </div>
      </div>
      <el-radio-group
        class="redRadio"
        v-model="search.isSingle"
        @change="searchClick"
        v-show="search.IsPurchase == 1"
      >
        <el-radio :label="0">资料包</el-radio>
        <el-radio :label="1">资料</el-radio>
      </el-radio-group>
    </div>
    <!-- 学习资料 -->
    <div class="content">
      <!-- 学习资料 -->
      <div class="list-two" v-if="list && list.length > 0">
        <div class="item" v-for="(item, index) in list" :key="index">
          <img
            v-show="search.isSingle == 0 && search.IsPurchase == 1"
            class="item-img"
            src="@/assets/img/homesixth/Group 36231.png"
          />
          <img
            v-show="search.isSingle == 1"
            class="item-img zl"
            src="@/assets/img/homesixth/Frame 13.png"
          />
          <div v-show="search.IsPurchase == 0" class="itemImgBox">
            <img
              class="item-img"
              src="@/assets/img/homesixth/Group 36231.png"
            />
            <!-- <img
              v-if="item.filetype == 'excel'"
              class="imgItem"
              src="@/assets/img/study/dataXls.png"
            />
            <img
              v-else-if="item.filetype == 'word'"
              class="imgItem"
              src="@/assets/img/study/dataDoc.png"
            />
            <img
              v-else-if="item.filetype == 'pdf'"
              class="imgItem"
              src="@/assets/img/study/dataPdf.png"
            />
            <img
              v-else-if="item.filetype == 'ppt'"
              class="imgItem"
              src="@/assets/img/study/dataPpt.png"
            />
            <img v-else class="imgItem" src="@/assets/img/study/dataFile.png" /> -->
          </div>
          <div class="item-content">
            <div class="item-text">{{ item.name }}</div>
            <div class="item-num">
              共{{ item.num ? item.num : 0 }}份资料
              <span v-if="item.className">/</span> {{ item.className }}
            </div>
            <!-- <div class="item-size">{{ item.size }}</div> -->
          </div>
          <div class="keep-learns fles-shrink" @click="toMaterialPage(item)">
            查看资料
          </div>
        </div>
      </div>
      <!-- 暂无课程 -->
      <div class="nothing" v-if="!list || list.length == 0">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <el-pagination
        class="pagination"
        :total="total"
        :current-page="pageNum"
        :page-sizes="[8, 16, 24, 40]"
        :page-size="pageSize"
        :pager-count="5"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { DataClient } from "@/api/dataClient";
let dataClient = new DataClient();
import { getInfo } from "@/api/cookies";

export default {
  components: {},

  data() {
    return {
      list: {},
      search: {
        IsPurchase: 1, // 1 赠送  0 购买
        isSingle: 0, // 0资料包 1 资料
      },
      pageSize: 8,
      pageNum: 1,
      total: 0,
      userInfo: null,
    };
  },
  created() {
    this.userInfo = getInfo();
    this.getList();
  },
  methods: {
    // 获取知识套餐列表
    getList() {
      if (this.search.IsPurchase == 1) {
        this.getMyClassDocPackageList();
      } else {
        dataClient
          .getMyDocPackageList(
            this.pageSize,
            this.pageNum,
            this.search.type ? this.search.type : undefined,
            this.userInfo ? this.userInfo.id : undefined
          )
          .then((res) => {
            this.list = res.rows;

            this.total = res.total;
            if (res.code) {
              this.list = [];
              this.total = 0;
            }
          });
      }
    },
    /* 获取赠送的资料/资料包 */
    getMyClassDocPackageList() {
      let that = this;
      dataClient
        .getMyClassDocPackageList(
          this.pageSize,
          this.pageNum,
          this.search.type ? this.search.type : undefined,
          this.userInfo ? this.userInfo.id : undefined,
          this.search.isSingle
        )
        .then((res) => {
          if (this.search.isSingle == 1) {
            for (let item of res.rows) {
              item.filetype = Vue.prototype.getFileType(item.names);
              Vue.prototype.getFilesize(item.names, function (size) {
                let sizeVal = (size / 1028).toFixed(0) + "k";
                let data = (size / 1028 / 1024).toFixed(0);
                item.size = data > 0 ? data + "M" : sizeVal;
                that.$forceUpdate();
              });
            }
          }
          this.list = res.rows;
          this.total = res.total;
          if (res.code) {
            this.list = [];
            this.total = 0;
          }
        });
    },

    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },
    /* ----------------------------------------------------- */
    isOk(val) {
      this.search = val;
      this.getList();
    },
    // 点击图片跳转到详情页
    checkClick(item) {
      this.$router.push(`/data/dataPackageDetail?packageId=${item.coursesId}`);
    },
    /* 赠送/购买筛选 */
    btnClick(val) {
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.search.IsPurchase = val;
      this.search.isSingle = 0;
      this.getList();
    },
    /* 资料/资料包筛选 */
    searchClick() {
      this.pageNum = 1;
      this.list = [];
      this.total = 0;
      this.getList();
    },
    /* 点击查看资料 */
    toMaterialPage(item) {
      if (this.search.isSingle == 0) {
        this.$router.push({
          path: `/data/materialPage`,
          query: {
            id: item.coursesId,
            title: item.name,
            isPurchase: 0,
          },
        });
      } else {
        let data = {
          zlId: item.mid,
          xmId: item.kind,
          linkLocation: item.names,
          type: 1,
        };
        dataClient.downloadRecord(data);
        this.$router.push({
          path: "/VIP/sourcePage",
          query: {
            seeNum: item.seeNum,
            names: item.names,
            id: this.id,
            title: this.title,
            schoolId: this.schoolId,
            type: 2,
            IsPurchase: this.IsPurchase == 1 ? false : true,
          },
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.kecheng {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background: #ffffff;
  padding-bottom: 20px;
  .dis_flex_between {
    padding: 24px 32px;
    background: #fff;
    .btnBox {
      display: flex;
      align-items: center;
      color: #666666;
      .btnItem {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 0px 4px 4px 0px;
        opacity: 1;
        border: 1px solid #ececec;
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 28px;
        cursor: pointer;
      }
      .on {
        background: #1061ff;
        color: #ffffff;
      }
    }
  }
  .content {
    min-height: calc(100% - 124px - 27px);
    overflow-y: scroll;
    .list-two {
      padding: 0px 32px;
    }
    .item {
      border-radius: 0px;
      border-bottom: 1px solid #eee;
      margin-bottom: 0px;
      height: 104px;
      padding-top: 24px;
      align-items: start;
      .keep-learns {
        width: 80px;
        height: 28px;
        background: #ffffff;
        border-radius: 14px 14px 14px 14px;
        opacity: 1;
        border: 1px solid #1061ff;
        text-align: center;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #1061ff;
        line-height: 28px;
        margin-top: 15px;
        cursor: pointer;
      }
      .zl {
        width: 48px;
        height: 56px;
      }
      img {
        width: 48px;
        height: 56px;
        margin-bottom: 40px;
      }
      .item-content {
        padding-right: 30px;
        .item-text {
          width: 500px;
          height: 16px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .item-num {
          font-size: 12px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          background: none;
          padding: 0;
        }
      }
      .itemImgBox {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        .imgItem {
          width: 40px;
          height: 40px;
          margin-right: 12px;
        }
      }
    }
  }
}
.list-two {
  padding: 0px;
  background: #fff;
}
.item-size {
  font-size: 12px;
}
/deep/ .el-radio__input.is-checked .el-radio__inner {
  border-color: #1061ff;
  background: #1061ff;
}
/deep/ .el-pagination .active {
  background: #1061ff !important;
}
</style>
